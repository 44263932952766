/* src/App.scss */

/* 1) Importer les couches de Tailwind : base, components */
@tailwind base;
@tailwind components;

/* 2) Vos styles globaux */
html,
body,
#root {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100%;
  position: relative;

  font-family: 'Assistant', sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;

  color: #18181b;

  /* Antialiasing */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  position: relative;
  width: 100%;
  min-height: 100%;
}

/* 3) Règles spécifiques (venant de l'ancien [data-component='App']) */
[data-component='App'] {
  min-height: 100%;
  width: 100%;
  position: relative;
}

/* 4) Composants du design system */
@layer components {
  /* Système de boutons */
  .voxa-button {
    @apply whitespace-nowrap px-8 py-4 uppercase font-semibold
           rounded-lg shadow-lg
           transition transform duration-200
           hover:scale-105 focus:outline-none focus:ring-4
           inline-flex justify-center items-center;
  }
  
  .voxa-button-primary {
    @apply bg-[#3f7afd] text-white
           hover:bg-[#2f69df] focus:ring-blue-300;
  }
  
  .voxa-button-secondary {
    @apply bg-white border border-[#3f7afd] text-[#3f7afd]
           hover:bg-gray-50 focus:ring-blue-200;
  }
  
  /* Nouveau style noir/blanc pour les boutons spéciaux */
  .voxa-button-blackwhite {
    @apply bg-white border border-black text-black
           hover:bg-gray-100 focus:ring-gray-300;
  }
  
  /* Système de cartes */
  .voxa-card {
    @apply rounded-lg border-2 p-4
           bg-white border-gray-300 shadow-sm
           transition duration-200
           hover:border-gray-400 hover:shadow-md;
  }
  
  .voxa-card-selected {
    @apply bg-blue-50 border-blue-400 shadow-md;
  }

  .pt-safe {
    padding-top: var(--safe-area-top);
  }
  
  .pb-safe {
    padding-bottom: var(--safe-area-bottom);
  }
}

/* 5) Appliquer automatiquement la typographie aux balises natives */
@layer base {
  h1 {
    @apply voxa-h1;
  }
  h2 {
    @apply voxa-h2;
  }
  h3 {
    @apply voxa-h3;
  }
  p {
    @apply voxa-p;
  }
  small {
    @apply voxa-small;
  }
  label {
    @apply voxa-label;
  }
}

/* 6) Inclure les utilitaires de Tailwind */
@tailwind utilities;

/* 7) Utilitaires du système typographique */
@layer utilities {
  /* --- TITRES --- */
  .voxa-h1 {
    @apply text-4xl sm:text-5xl md:text-6xl lg:text-7xl
           font-extrabold text-vox-brand leading-normal md:leading-tight;
    display: inline-block;
    max-width: 100%;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
    min-height: min-content;
  }
  
  .voxa-h2 {
    @apply text-3xl sm:text-4xl md:text-5xl lg:text-6xl
           font-bold text-vox-brand leading-tight;
  }
  
  .voxa-h3 {
    @apply text-2xl sm:text-3xl md:text-4xl
           font-bold text-vox-brand leading-tight;
  }
  
  /* --- PARAGRAPHES --- */
  .voxa-p {
    @apply text-lg sm:text-xl md:text-2xl lg:text-3xl
           text-gray-800 leading-snug;
  }
  
  /* --- TEXTE PLUS PETIT (on remplace text-gray-600 par text-black) --- */
  .voxa-small {
    @apply text-base sm:text-lg md:text-xl
           text-black leading-normal;
  }
  
  /* --- LABELS, ÉTIQUETTES --- */
  .voxa-label {
    @apply text-base sm:text-lg md:text-xl
           font-semibold text-gray-700 leading-tight;
  }
  
  /* --- ÉLÉMENTS D'INTERFACE --- */
  .voxa-ui-text {
    @apply text-lg sm:text-xl md:text-2xl lg:text-3xl
           leading-snug font-semibold;
  }
}

:root {
  --safe-area-top: env(safe-area-inset-top, 0px);
  --safe-area-bottom: env(safe-area-inset-bottom, 0px);
  --safe-area-left: env(safe-area-inset-left, 0px);
  --safe-area-right: env(safe-area-inset-right, 0px);
}

/* Ajouter cette animation avec les autres animations */
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}